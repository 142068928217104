import React, { useState } from "react";
import Layout from "../Layout";
import "./style.scss";
import Button from "../ButtonComponent";

import axios from "axios";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  experience: "",
  ctc: "",
  position: "",
  location: "",
  resume: null,
};

const vacancies = [
  {
    profile: "Marketing Associate",
    des:
      "Black Elephant Digital invites applications for a Marketing Associate position at its Gurgaon studio.<br/><br/>The ideal candidate is a highly organized, tech-savvy self-starter who will collaborate with the marketing team to build and strategize new marketing campaigns. You will be managing different projects and providing support for advertising campaigns. ",
    responsibilities: [
      "Report marketing activity",
      "Contribute to and coordinate marketing campaigns",
      "Generate, edit, publish and share engaging content daily (e.g. original text, photos, videos and news)",
      "Monitor SEO and web traffic metrics",
      "Coordinate with brands/ clients",
    ],
    qualifications: [
      "Bachelor's degree or relevant work experience in the field of digital marketing",
      "Demonstrable expertise with digital marketing tools",
      "<strong>Excellent copywriting skills</strong>",
      "Ability to deliver creative content (text, image and video)",
      "Solid knowledge of SEO, keyword research and Google Analytics",
      "Knowledge of online marketing channels",
      "Familiarity with web design",
      "Excellent communication skills",
      "Analytical and multitasking skills",
      "Certifications in online tools such as Google Ads, Hootsuite, SEMrush, Mailchimp would be an added advantage.",
    ],

    icon: "https://blackelephantdigital.com/assets/img/Icon3.png",

    shortdes:
      "Black Elephant Digital invites applications for a Marketing Associate position at its Gurgaon studio.",
    remuneration: "Competitive and commensurate with experience.",
    type: "Full-Time",
    location: "Gurugram",
    headerColor: "#a2d2ff",
  },

  {
    profile: "Frontend Developer",
    des:
      "Black Elephant Digital invites applications from frontend developers for a full-time position in their Gurgaon studio.",

    requirements: [
      // "Excellent computer knowledge for both MAC and PC environments",
      "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
      "Thorough understanding of React.js and its core principles",
      "Experience with popular React.js workflows (such as Flux or Redux)",
      "Familiarity with newer specifications of EcmaScript(ES6)",
      "Experience with data structure libraries (e.g., Immutable.js)",
      "Knowledge of isomorphic React is a plus",
      "Familiarity with RESTful APIs",
      "Knowledge of modern authorization mechanisms, such as JSON Web Token",
      "Familiarity with modern front-end build pipelines and tools",
      "Experience with common front-end development tools such as Babel, Webpack, NPM, etc.",
      "Ability to understand business requirements and translate them into technical requirements",
      "A knack for benchmarking and optimization",
      "Excellent troubleshooting skills.",
      "Good project management skills.",
    ],

    icon: "https://blackelephantdigital.com/assets/img/Icon3.png",
    shortdes:
      "Black Elephant Digital invites applications from frontend developers for a full-time position in their Gurgaon studio.",

    remuneration: "Commensurate with experience",
    type: "Full-Time",
    location: "Gurugram",
    headerColor: "#FF7777",
  },
];

export default function Careers() {
  const [values, setValues] = useState(initialValues);

  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [showCardDetails, setShowCardDetails] = useState(false);
  const [currentJD, setCurrentJD] = useState({});

  function handleOnChange(e) {
    if (e.target.name !== "resume") {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: e.target.files[0] });
    }
  }

  function toggleCardDetails() {
    setShowCardDetails(!showCardDetails);
  }

  function handleJDClick(jd) {
    setCurrentJD(jd);
    toggleCardDetails();
  }

  async function onFormSubmit(e) {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phone,
      experience,
      ctc,
      location,
      position,
      resume,
    } = values;

    setIsSubmitting(true);

    if (values.firstName === "") {
      setErrors({ firstName: "Please Enter Your First Name!" });
      setIsSubmitting(false);
      return;
    }

    if (values.lastName === "") {
      setErrors({ lastName: "Please Enter Your last Name!" });
      setIsSubmitting(false);
      return;
    }

    if (values.email === "") {
      setErrors({ email: "Please Enter Email Address!" });
      setIsSubmitting(false);
      return;
    }

    if (!isEmailValid(values.email)) {
      setErrors({ email: "Invalid Email Address!" });
      setIsSubmitting(false);
      return;
    }

    if (values.phone === "") {
      setErrors({ phone: "Please Enter Your Phone!" });
      setIsSubmitting(false);
      return;
    }

    if (values.experience === "") {
      setErrors({ experience: "Please Enter Years of Experience!" });
      setIsSubmitting(false);
      return;
    }

    if (values.ctc === "") {
      setErrors({ ctc: "Please Enter Your Last CTC" });
      setIsSubmitting(false);
      return;
    }

    if (values.position === "") {
      setErrors({ position: "Please Enter Job Position" });
      setIsSubmitting(false);
      return;
    }

    if (values.location === "") {
      setErrors({ location: "Please Enter Your Current Location" });
      setIsSubmitting(false);
      return;
    }

    if (values.resume === null) {
      setErrors({ resume: "Please upload CV/Resume" });
      setIsSubmitting(false);
      return;
    }

    if (
      values.resume.type !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      values.resume.type !== "application/pdf" &&
      values.resume.type !== "application/msword"
    ) {
      setErrors({
        resume: "Please upload .pdf/.doc/.docx file only",
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const formData = new FormData();

      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("experience", experience);
      formData.append("ctc", ctc);
      formData.append("location", location);
      formData.append("position", position);
      formData.append("resume", resume);

      const response = await axios.post(
        "https://blackelephantdigital.com/api/public/index.php/careers",
        formData
      );

      console.log(response);

      setIsSubmitting(false);
      setShowSuccess(true);

      setValues(initialValues);

      const form = document.querySelector(".careers__form");
      form.reset();
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (e) {
      console.log(e);
      if (e.response) {
        console.log(e.response.data);
        setErrors({ msg: "Internal Server Error!" });
      } else {
        setErrors({
          msg:
            "Network Error! Please check if you are connected to the internet.",
        });
        setIsSubmitting(false);

        setTimeout(() => {
          setErrors({ ...errors, msg: null });
        }, 5000);
      }
    }
  }

  function isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <Layout>
      <div className="careers">
        <div className="careers__hero">
          <h1 className="careers__heading">Careers</h1>

          <p>
            We're always on the lookout for talented professionals, with a
            passion for quality, design, pithy copy, coding and coffee.
          </p>
        </div>

        <div className="careers__vacancies">
          <h2 className="careers__vacancies__heading">Vacancies</h2>
          {!showCardDetails ? (
            <div className="row careers__vacancies__row">
              {vacancies.map((item, i) => (
                <div className="col col-12 col-md-6 col-xl-4">
                  <div
                    key={`vacancy+${i}`}
                    className="careers__vacancies__card no-select"
                    onClick={() => handleJDClick(item)}
                  >
                    <div
                      className="careers__vacancies__card__header"
                      style={{ backgroundColor: item.headerColor }}
                    >
                      <div className="careers__vacancies__card__profile">
                        {item.profile}
                      </div>

                      <img
                        className="careers__vacancies__card__icon"
                        src={
                          "https://blackelephantdigital.com/assets/img/Icon3.png"
                        }
                      ></img>
                    </div>
                    <div className="careers__vacancies__card__des">
                      <div>Type: {item.type}</div>
                      <div>Location: {item.location}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="careers__vacancies__jobdetails">
              <button
                onClick={() => {
                  toggleCardDetails();
                  setCurrentJD({});
                }}
                className="careers__vacancies__jobdetails__back"
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              <h2 className="careers__vacancies__jobdetails__heading">
                {currentJD.profile}
              </h2>

              <p
                className="careers__vacancies__jobdetails__des"
                dangerouslySetInnerHTML={{ __html: currentJD.des }}
              ></p>

              {currentJD.responsibilities && (
                <div className="careers__vacancies__jobdetails__res">
                  <div className="careers__vacancies__jobdetails__res__heading">
                    Key Responsibilities
                  </div>

                  <ul>
                    {currentJD.responsibilities.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              )}

              {currentJD.qualifications && (
                <div className="careers__vacancies__jobdetails__res">
                  <div className="careers__vacancies__jobdetails__res__heading">
                    Qualifications
                  </div>

                  <ul>
                    {currentJD.qualifications.map((item) => (
                      <li dangerouslySetInnerHTML={{ __html: item }}></li>
                    ))}
                  </ul>
                </div>
              )}

              {currentJD.requirements && (
                <div className="careers__vacancies__jobdetails__res">
                  <div className="careers__vacancies__jobdetails__res__heading">
                    Job Requirements
                  </div>

                  <ul>
                    {currentJD.requirements.map((item) => (
                      <li dangerouslySetInnerHTML={{ __html: item }}></li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="careers__vacancies__jobdetails__res">
                <div className="careers__vacancies__jobdetails__res__heading">
                  Remuneration
                </div>

                <div className="careers__vacancies__jobdetails__res__text">
                  {currentJD.remuneration}
                </div>
              </div>
            </div>
          )}
        </div>

        <form className="careers__form" onSubmit={onFormSubmit}>
          <img
            alt=""
            draggable="false"
            className="careers__form__background"
            src="https://image.freepik.com/free-vector/set-social-media-doodles_6997-1434.jpg"
          />
          {/* <div className="careers__form__heading">Get In Touch</div> */}

          <div className="careers__form__group">
            <div className="careers__form__group__field">
              <label>
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="firstName"
                value={values.firstName}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.firstName && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
            <div className="careers__form__group__field">
              <label>
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="lastName"
                value={values.lastName}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.lastName && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
          </div>

          <div className="careers__form__group">
            <div className="careers__form__group__field">
              <label>
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="email"
                value={values.email}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.email && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
            <div className="careers__form__group__field">
              <label>
                Phone<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="phone"
                value={values.phone}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.phone && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
          </div>

          <div className="careers__form__group">
            <div className="careers__form__group__field">
              <label>
                Total Years Of Experience<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="experience"
                value={values.experience}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.experience && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
            <div className="careers__form__group__field">
              <label>
                Last CTC<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="ctc"
                value={values.ctc}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.ctc && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
          </div>

          <div className="careers__form__group">
            <div className="careers__form__group__field">
              <label>
                Position<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="position"
                value={values.position}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.position && "careers__form__input--invalid"
                }`}
              ></input>
            </div>

            <div className="careers__form__group__field">
              <label>
                Current Location<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="location"
                value={values.location}
                onChange={handleOnChange}
                className={`careers__form__input ${
                  errors.location && "careers__form__input--invalid"
                }`}
              ></input>
            </div>
          </div>
          <label>
            CV / Resume<span style={{ color: "red" }}>*</span>
          </label>
          <input
            name="resume"
            type="file"
            accept=".pdf,.docx,.doc"
            className={`careers__form__input ${
              errors.resume && "careers__form__input--invalid"
            }`}
            onChange={handleOnChange}
          ></input>

          {/* <button className="btn btn-dark mt-10"></button> */}
          <div className="btn mt-10 tal">
            <Button
              className="btn-light"
              text={isSubmiting ? "...submitting" : "submit"}
              fillColor="black"
            />
          </div>

          {errors.phone && (
            <div className="careers__form__error">
              <small>{errors.phone}</small>
            </div>
          )}
          {errors.email && (
            <div className="careers__form__error">
              <small>{errors.email}</small>
            </div>
          )}
          {errors.firstName && (
            <div className="careers__form__error">
              <small>{errors.firstName}</small>
            </div>
          )}
          {errors.lastName && (
            <div className="careers__form__error">
              <small>{errors.lastName}</small>
            </div>
          )}

          {errors.experience && (
            <div className="careers__form__error">
              <small>{errors.experience}</small>
            </div>
          )}

          {errors.ctc && (
            <div className="careers__form__error">
              <small>{errors.ctc}</small>
            </div>
          )}

          {errors.position && (
            <div className="careers__form__error">
              <small>{errors.position}</small>
            </div>
          )}

          {errors.location && (
            <div className="careers__form__error">
              <small>{errors.location}</small>
            </div>
          )}

          {errors.resume && (
            <div className="careers__form__error">
              <small>{errors.resume}</small>
            </div>
          )}

          {showSuccess && (
            <div className="careers__form__success">
              <small> Thank you. Your response have been recorded.</small>
            </div>
          )}

          {/* <div className="careers__form__meetus">Meet us here</div> */}
        </form>
      </div>
    </Layout>
  );
}
